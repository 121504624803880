import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Partenaires from "components/Partenaires";
import { LazyLoadImage } from "react-lazy-load-image-component";

/* Markdown Converter */
const showdown = require("showdown");
const converter = new showdown.Converter();

const OtherCountriesPages = ({ pageContext: content, location }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _partners,
    _staticTexts,
    _richTextBlock,
    _topMenus,
    _companyMenus,
    _lang,
 _blogs,
  } = content;

  // Get Sidebar content from Contentful
  const getSidebarContent = useMemo(() => {
    const newSidebarContent = {
      footer: "",
    };

    let _sidebarContent = _richTextBlock.find(
      (tb) => tb.title === "sidebar_other_countries"
    );

    if (_sidebarContent) {
      try {
        _sidebarContent = _sidebarContent.richText.content;

        newSidebarContent.footer = _sidebarContent[0].content[0].value;
      } catch (error) {
        console.log(error);
      }
    }

    return newSidebarContent;
  }, [_richTextBlock]);

  const [sidebarContent] = useState(getSidebarContent);

  const onBtnClick = () => {
    window.open("https://losali.com?lang=" + _lang);
  };

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      menuSection={content.menuSection}
      menuTitle={content.menuTitle}
      slug={content.slug}
      ratingValue={content.ratingValue || 4.9}
      ratingCount={content.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{content.pageTitle} | Losali Direct</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={content.metaKeywords} />
        <meta name="description" content={content.metaDescription} />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="page2-box1 card-primary">
          <div className="other-card page2-card-left">
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(content.body),
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <LazyLoadImage
                src={`https:${content.image.fields.file.url}`}
                alt={content.altText}
                width="100"
              />
            </div>
            <button className="losali-btn" onClick={onBtnClick}>
              {_staticTexts.goToLosali}
            </button>
          </div>
        </div>

        <div className="page2-box2 hide-mobile">
          <div className="other-card-right page2-card-right card-primary">
            <div className="page2-right-text">
              <h3>{sidebarContent.footer}</h3>

              <div className="social-media">
                <span className="social-media-like float-left"></span>
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.losalidirect.com%2F&display=popup&ref=plugin&src=like&kid_directed_site=0"
                  target="_blank"
                  rel="noreferrer"
                  className="social-media-text"
                >
                  {_staticTexts.like}
                </a>
              </div>

              <div className="social-media">
                <span className="social-media-share float-left"></span>
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(`https://losalidirect.com`);
                  }}
                  aria-hidden="true"
                  className="social-media-text"
                >
                  {_staticTexts.share}
                </span>
              </div>

              <div className="social-media">
                <span className="social-media-tweet float-left"></span>
                <a
                  href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Flosalidirect.com%2F&ref_src=twsrc%5Etfw&text=Avec%20Losali%20Direct%20appelez%20les%20num%C3%A9ros%20courts%20et%20surtax%C3%A9s%20depuis%20l%27%C3%A9tranger&tw_p=tweetbutton&url=https%3A%2F%2Fwww.losalidirect.com%2F&via=Narga"
                  target="_blank"
                  rel="noreferrer"
                  className="social-media-text"
                >
                  {_staticTexts.tweet}
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Partenaires partners={_partners} />
    </Layout>
  );
};

export default OtherCountriesPages;
